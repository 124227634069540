jQuery(function($) {

    "use strict";

    // To prevent jshint errors
    /*global Modernizr:true */
    /*jshint devel:true */
    /*global $:false */

    // Make use of event bubbling to account for programs being added/removed dynamically
    $('.facetwp-template').on('changed.bs.select', '.booking-select', function(event) {
        event.preventDefault();

        // Prevent defualt action
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = false;
        }

        // setup vars
        var parent = $(this).parents('.program'),
          select = $(parent).find('select'),
          program_id = $(select).data('program-id'),
          selecetd_location = $(select).val(),
          booking_btn = $(parent).find('.btn-book');

          // console.log( booking_btn );

        // Set the button booking url for the affected program
        if (selecetd_location != '') {
          setBookingBtnUrl(booking_btn, program_id, selecetd_location);
        } else {
          $(booking_btn).addClass('disabled').attr('href', '#');
        }

    });

}(jQuery));

// triggered when FacetWP finishes refreshing
jQuery(document).on('facetwp-loaded', function() {

    // console.log('facetwp-loaded triggered');

    jQuery('.selectpicker').selectpicker('refresh');
    jQuery('.selectpicker').selectpicker('setStyle', 'btn-ghost--drk', 'add');

    // Get the location param from url
    var locationParam = getURLParameter('fwp_program_location');

    // Check if location param isset
    if (locationParam != '' && locationParam != null) {

        // Convert the location param to an array
        var locationArray = locationParam.split(',');
        // Set location param to first in array
        locationParam = locationArray[0];

        // Set the location for booking based on location facet value
        setSelectLocations(locationParam);
    }

});

// triggered before FacetWP begins the refresh process
// $(document).on('facetwp-refresh', function() {

//     console.log('facetwp-refresh triggered');

// });




/*=================================
=            Functions            =
=================================*/

// Function to get a url param value given the name of the param
function getURLParameter(name) {
    "use strict";
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
}

// Set select menus to specified location and booking url
function setSelectLocations(location) {
    "use strict";

    $('.facetwp-template').children('.program').each(function(index, el) {

        // setup vars
        var selectMenu = $(this).find('select'),
            program_id = $(selectMenu).data('program-id'),
            selecetd_location = location,
            booking_btn = $(this).find('.btn-book');

        $(selectMenu).val(location);
        $('.selectpicker').selectpicker('render');

        setBookingBtnUrl(booking_btn, program_id, selecetd_location);

    });
}

// Using ajax, set the booking url for a program given the location
function setBookingBtnUrl(booking_btn, program_id, selecetd_location) {
    "use strict";
    var data = {
        action: 'get_url',
        gbu_nonce: gbu_vars.gbu_nonce,
        program_id: program_id,
        selecetd_location: selecetd_location
    };

    jQuery.ajax({
        type: 'post',
        dataType: 'html',
        url: gbu_vars.gbu_ajax_url,
        data: data,
        beforeSend: function() {
            jQuery(booking_btn).html( 'Loading...' );
            booking_btn.addClass('disabled');
        },
        success: function( data, textStatus, jqXHR ) {
            // console.log(data);
            booking_btn.attr('href', data);
            booking_btn.html( 'Book Now' );
            booking_btn.removeClass('disabled');
        },
        error: function( MLHttpRequest, textStatus, errorThrown ) {
            booking_btn.html( 'Nothing Found' );
        }
    });
}
